@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.shopWrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  height: 330px;
  display: flex;
}

.itemTitleSelector {
  font-size: 1.5rem;
  font-family: 'Oregano';
  cursor: pointer;
  transition: 0.2s;
  background-color: $blueSecColor;
  padding: 0px 20px;
  border-radius: 20px;
}

.selectedCategory {
  background-color: #0057aa;
}

.itemTitleSelector:hover {
  transform: scale(1.05);
}

.imageProfile {
  width: 200px;
}

.imageWrapper {
  width: 100%;
}

.shopWrapper>div {
  height: 100px;
  width: 300px;
}

.controllWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Oregano';
  font-size: 0.8rem;
  border-radius: 20px;
  gap: 10px;
}

.costWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Oregano';
  font-size: 0.8rem;
  background-color: $blueSecColor;
  border-radius: 20px;
  width: 100%;
  cursor: pointer;
  border: 0px;
  outline: 0px;
  color: white;
}

.previewWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: 'Oregano';
  font-size: 0.8rem;
  background-color: $greenDarkSecColor;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 5px;
  border: 0px;
  outline: 0px;
  color: white;
}

.previewWrapper>h1 {
  margin: 0px;
}

.costWrapper>h1 {
  margin: 10px 0px;
}

.loadingPurchase {
  margin-top: 20px;
  height: 20px;
  width: 20px;
  animation: animateLoad 3s linear infinite;
}

@keyframes animateLoad {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}