@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.linkCSS * {
    box-sizing: border-box;
}

.linkCSS {
    text-decoration: none;
    color: white;
    flex: 1 0 300px;
    min-height: 200px;
}

.titleSmallDot {
    margin-top: 10px;
}

.listItemContainer {
    background-color: $secColor;
    padding: 25px 20px;
    border-radius: 20px;
    height: 100%;
    display: flex;
    margin: 0px 2px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: .8rem;
    transition: 0.2s;
}

.listItemContainer:hover {
    transform: scale(1.01);
}