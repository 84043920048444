@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.wrapperEqualCalc {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 20px;
  margin: 10px 0px;
}

.wrapperEqualCalc * {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.icon {
  margin: 5px 0px;
  height: 60px;
  width: 60px;
}

.wrapperIconAndText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-basis: 0px;
  align-items: center;
  flex-grow: 1;
  font-family: 'Noto IKEA';
  font-size: 0.8rem;
}

.header {
  font-family: 'Oregano';
  font-size: 1.2rem;
  text-align: center;
}