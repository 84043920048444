@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

body {
  @media only screen and (max-width: 950px) {
    .grid {
      grid-template-columns: repeat(2, 1fr);
      min-width: 0px;
    }

    .wrapper {
      height: auto;
      padding-bottom: 10px;
      min-width: 0px;
    }
  }
}

.wrapper {
  display: inline-block;
  width: 100%;
  height: 430px;
  border-radius: 20px;
  min-width: 600px;
  background-color: $secColor;
}

.grid {
  display: grid;
  padding: 0px 10px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.grid>div {
  height: 100%;
}

.completeTaskButton {
  border-radius: 10px;
  color: white;
  background-color: $blueSecColor;
  border: 0px;
  padding: 8px;
  transition: 0.2s;
  font-size: 1.1rem;
  font-family: 'Oregano';
}

.completeTaskButton:hover {
  cursor: pointer;
  background-color: $greenDarkSecColor;
  transform: scale(1.05);
}

.challengeWrapperText {
  text-align: center;
  font-size: 1.1rem;
  margin-top: 1px;
  margin-bottom: 0px;

  font-family: 'Oregano';
}

.title {
  font-size: 2rem;
  color: white;
  text-align: center;
  font-family: 'Oregano';
}

.challengeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 200px;
  padding: 5px;
  margin: 10px;
  background: white;
  border-radius: 20px;
  height: 100%;
  justify-content: space-around;
}

.backgroundOverLay {
  z-index: 100;
  display: flex;
  top: 0px;
  left: 0px;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.completeChallengeWrapper {
  background-color: $secColor;
  color: white;
  padding: 50px 100px 50px 100px;
  border-radius: 40px;
}

.completeTitle {
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  font-family: 'Oregano';
}

.completeChallengeName {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 10px;
  font-family: 'Oregano';
}

.completeButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.closeButton {
  border-radius: 10px;
  width: 50px;
  padding: 5px 0px;
  background-color: rgb(196, 45, 45);
  border: none;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  font-family: 'Oregano';
}

.submitButton {
  border-radius: 10px;
  padding: 5px 10px;
  margin: 0px 0px 0px 30px;
  background-color: $blueSecColor;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  font-family: 'Oregano';
}

.completedTaskButton {
  border-radius: 10px;
  color: white;
  background: rgb(50, 30, 121);
  background: linear-gradient(180deg, rgba(50, 30, 121, 1) 0%, rgba(50, 30, 121, 1) 25%, rgba(30, 18, 71, 1) 100%);
  border: 0px;
  padding: 8px;
  transition: 0.2s;
  font-size: 1.1rem;
  font-family: 'Oregano';
}

.loading {
  width: 102px;
  height: 12px;
  animation: animateLoad 3s linear infinite;
}

@keyframes animateLoad {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}