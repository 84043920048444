.hamburgerMenu {
    color: white;
    position: sticky;
    top: 25px;
    right: 15px;
    z-index: 8;
}

// the container for the 3 strokes
.burger {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
}

// the 3 strokes for the menu
.burgerStroke {
    height: 3px;
    width: 25px;
    margin-top: 5px;
    background-color: white;
    border-radius: 5px;
    transition: transform .5s cubic-bezier(0.36, 1.07, 1, 0.96), opacity .1s linear .2s;
}

// the overlay for showing the links
.burgerNav {
    height: 100vh;
    min-width: 100vw;
    // top: -25px;
    // left: -98vw;
    // position: absolute;
    background: rgba(0, 0, 0, .9);
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: hidden;
}

// the container in the overlay containing the links
.linkContainer {
    width: 70%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.linkContainer>div {
    flex: 1 1 1px;
    border-bottom: 1px solid white;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.burgerNav a {
    color: white;
    text-decoration: none;

}

.hamburgerMenu:hover {
    cursor: pointer;
}

.firstBurgerStroke {
    transform: rotate(45deg);
    transform-origin: 4px 6px;
    transition: transform-origin .5s linear;
}

.secondBurgerStroke {
    opacity: 0;
}

.thirdBurgerStroke {
    transform: rotate(-45deg);
    transform-origin: 2px -2px;
    transition: transform-origin .5s linear;
}