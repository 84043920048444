@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.itemContainer {
    background-color: white;
    margin-right: 10px;
    border-radius: 25%;
    height: 150px;
    flex-basis: 150px;
    border: 3px solid white;
    position: relative;
}

.itemContainer,
.itemContainer * {
    transition: background-color .2s, opacity .3s, color .2s;
}

.itemContainer:hover {
    background-color: lightgrey;
}

.boilerPlate {
    width: 150px;
}

.itemContainer:hover>.overlay {
    display: flex;
    opacity: 1;
}

.overlay {
    height: 150px;
    width: 150px;
    display: none;
    border-radius: 25%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(211, 211, 211, .5);
    opacity: 0;
}

.wearBtn {
    cursor: pointer;
    height: 30%;
    width: 70%;
    border: 3px solid white;
    color: white;
    border-radius: 10px;
    background-color: lightgrey;
    font-size: 15px;
    font-weight: bold;
}

.wearBtn:hover {
    background-color: $secColor;
    color: $blueSecColor;
}

.wearing {
    border: 3px solid $blueSecColor;
    background-color: rgba(118, 118, 118, .8);
    background-color: $secColor;
}