@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

body {
  @media only screen and (max-width: 950px) {
    .infoWrapper {
      width: calc(100vw - 20px);
    }

    .profileImageWrapper {
      left: calc(50vw - 58%);
    }
  }
}

.wrapper {
  display: flex;
  height: 200px;
}

.profileImageWrapper {
  position: relative;
  bottom: 70px;
  left: 83px;
  background-color: #CDEDFF;
  height: 120px;
  width: 120px;
  border-radius: 100px;
  border: 2px solid white;
  -webkit-box-shadow: 0px 0px 14px 0px #ffffff;
  box-shadow: 0px 0px 10px 0px #ffffff;
}

.profileImageWrapper>img {
  overflow: hidden;
  border-radius: 100px;
  width: inherit;
  height: inherit;
}

.infoWrapper {
  background-color: $secColor;
  text-align: center;
  border-radius: 20px;
  padding-top: 60px;
  width: 100%;
}

.userName {
  font-size: 1.8rem;
  margin: 5px 0px 0px 0px;
  font-family: 'Oregano';
  color: white;
}

.cdsLocation {
  font-size: 1rem;
  margin: 2px 0px 0px 0px;
  font-weight: lighter;
  font-family: 'Oregano';
  color: white;
}

.expWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 35px 10px 50px 10px;
}

.expBar {
  display: grid;
  grid-template-columns: 1fr;
  height: 16px;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  border-radius: 100px;
}

.exp {
  grid-column-start: 1;
  grid-row-start: 1;
  border-radius: 100px;
  width: 80%;
  background-color: $renGreen;
}

.noExp {
  grid-column-start: 1;
  grid-row-start: 1;
  border-radius: 100px;
  background-color: rgb(228, 224, 224);
  width: 100%;
}

.levelwrapper {
  display: flex;
  justify-content: center;
  height: 10px;
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
}

.level {
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 23px;
  width: 55px;
  height: 55px;
  color: white;
  border-radius: 1000px;
  border: 3px solid $renGreen;
  background-color: $secColor;
}

.pineConeWrapper {
  position: relative;
  bottom: 18px;
  margin-right: 5px;
  display: flex;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
}

.expCount {
  display: inline-block;
  align-self: flex-end;
  font-size: 0.8rem;
  color: white;
  margin: 0px;
  margin-left: 5px;
  justify-content: baseline;
  font-family: 'Oregano';
}

.pineConeWrapper>div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.pineConeWrapper>div>img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  height: 15px;
  width: 15px;
}

.pineConeWrapper>div>p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  color: white;
  margin: 0px 5px;
}