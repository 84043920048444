@font-face {
  font-family: 'Noto IKEA';
  src: url('../assets/fonts/NotoSansIkea-Regular.woff') format('woff');
  font-display: fallback;
  font-weight: normal;
}

@font-face {
  font-family: 'Noto IKEA';
  src: url('../assets/fonts/NotoSansIkea-Bold.woff') format('woff');
  font-display: fallback;
  font-weight: bold;
}

@font-face {
  font-family: 'Oregano';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('../assets/fonts/Oregano-Regular.woff') format('woff');
}