@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

body {
  @media only screen and (max-width: 950px) {
    .wrapper {
      width: calc(100vw - 10px);
      margin-bottom: 0px;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  color: white;
  text-align: center;
  border-radius: 20px;
  background-color: $secColor;
  height: 200px;
}

.title {
  font-family: 'Oregano';
  font-size: 2rem;
  margin: 0px 20px;
}

.text {
  font-size: .8rem;
  margin: 10px 10px;
}