@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

body {
  @media only screen and (max-width: 950px) {
    .tree {
      display: none;
    }

    .treeWrapper {
      width: 0px;
    }

    .wrapper {
      width: calc(100vw - 10px);
    }

  }
}

.wrapper {
  display: flex;
  color: white;
  min-height: 200px;
  border-radius: 20px;
  background: rgb(50, 30, 121);
  background: linear-gradient(180deg, rgba(50, 30, 121, 1) 0%, rgba(50, 30, 121, 1) 25%, rgba(30, 18, 71, 1) 100%);
}

.wrapper>div {
  display: flex;
  flex-direction: column;
  padding: 20px 15px 0px 15px;
}

.title {
  font-size: 1.5rem;
  margin: 0px;
  font-family: 'Oregano';
  // text-transform: uppercase;
}

.text {
  font-size: 0.8rem;
  font-family: 'Noto IKEA';
}

.learnMore {
  width: 200px;
  height: 40px;
  background-color: $blueSecColor;
  margin-top: auto;
  margin-bottom: 10px;
  border: none;
  border-radius: 10px;
  color: white;
  font-family: 'Oregano';
  font-size: 1.1rem;
}

.learnMore:hover {
  cursor: pointer;
}

.treeWrapper {
  width: 100%;
}

.tree {
  margin-left: auto;
  width: 120px;
}