@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

nav {
  height: 500px;
  position: fixed;
  display: flex;
  width: 100px;
  color: rgb(255, 255, 255);
  border-radius: "25px";
  z-index: 5;
}

#navContainer {
  display: flex;
  flex-direction: column;
  // height: 60%;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: "25px"
}

// the divs containing the a and text
#navContainer>div {
  display: flex;
  align-items: center;
  flex-basis: 25%;
  position: relative;
  width: 50px;
}

// the navlinks and the textboxes
nav a {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: $secColor;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.navIcons {
  height: 60%;
}

nav a+div {
  background-color: $mainColor;
  display: flex;
  align-items: center;
  transition: width .3s ease-out;
  height: 50px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.closedNav {
  opacity: 0;
  overflow: hidden;
  width: 0;
}

#navContainer>div>a:hover {
  background: linear-gradient(180deg, #321e79 0%, #321e79 25%, #1e1247 100%);
}

nav a:hover+div {
  position: absolute;
  left: 60px;
  width: 190px;
  white-space: nowrap;
  opacity: 1;
  font-size: 1.4rem;
  font-family: 'Oregano';
}

.slackIcon {
  height: 50px;
}