html {
  /* font-family: Noto IKEA,Noto Sans,Roboto,Open Sans,-apple-system,sans-serif !important; */
  font-family: Noto Sans, Roboto, Open Sans, -apple-system, sans-serif !important;
}

body {
  position: relative;
  margin: 0;
  font-family: 'Noto IKEA', 'Noto Sans', 'Roboto', 'Open Sans', sans-serif;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100vw;
  background-color: #18182B;
}

.App {
  max-width: 100vw;
}

.grid {
  margin: 0px;
}

.bodyFixed {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.swagger-ui {
  background-color: white !important;
}

.generalLink {
  text-decoration: none;
  color: white;

}

nav {
  outline: none !important;
}