@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

body {
  @media only screen and (max-width: 950px) {

    .fullWrapper {
      width: calc(100vw - 10px);
    }

    .responsiveCSS {
      flex-direction: column;
    }
  }
}

.wrapperTitleBig {
  font-family: 'Oregano';
  text-align: center;
  color: white;
  flex-grow: 1;
  border-radius: 20px;
  padding: 20px;
  font-size: 1.2rem;
  background: rgb(50, 30, 121);
  background: linear-gradient(180deg, rgba(50, 30, 121, 1) 0%, rgba(50, 30, 121, 1) 25%, rgba(30, 18, 71, 1) 100%);
}

.wrapper {
  display: inline-block;
  color: white;
  background-color: $secColor;
  flex-grow: 1;
  border-radius: 20px;
  padding: 20px;
}

.fullWrapper *,
.wrapper * {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.titleSmall {
  text-transform: uppercase;
  margin: 20px 0px 10px 0px;
  font-size: 1.5rem;
  font-family: 'Oregano';
}

.teamWrapper {
  height: 1000px;
}

.categoryBox {
  user-select: none;
  font-family: "Oregano";
  font-size: 1.3rem;
  height: 150px;
  width: 150px;
  border-radius: 25%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid white;
  transition: background-color .2s, color .2s;
}

.categoryBox:hover {
  cursor: pointer;
  background: linear-gradient(180deg, rgba(50, 30, 121, 1) 0%, rgba(50, 30, 121, 1) 25%, rgba(30, 18, 71, 1) 100%);
}

.categoryBox>p::first-letter {
  text-transform: capitalize;
}

.categoryBoxContainer {
  display: flex;
}

.categoryIcons {
  height: 20%;
}

.categoryNoShow {
  display: none;
}

.ownedItemsContainer {
  display: flex;
  overflow-y: auto;
}

.saveBtn {
  width: 40%;
}

.buttonStyle {
  color: white;
  background: linear-gradient(180deg, rgba(50, 30, 121, 1) 0%, rgba(50, 30, 121, 1) 25%, rgba(30, 18, 71, 1) 100%);
}