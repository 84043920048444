@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

body {
  @media only screen and (max-width: 950px) {

    .fullWrapper {
      width: calc(100vw - 10px);
    }

    .fullWrapper>div {
      margin-left: 0;
    }

    .responsiveCSS {
      flex-direction: column;
      height: auto;
    }

    .bigContainer {
      min-width: calc(100vw - 10px);
      flex-basis: 0px;
      height: auto;
    }

    .contentBox {
      overflow-y: unset;
      margin-bottom: 10px;
    }

    .leftTextContainer {
      max-height: auto;
      flex-grow: 0;
    }

    .tipsContainer {
      overflow-y: unset;
      max-height: auto;
    }

    .slackContainer {
      display: none;
    }

    .gridContainer {
      flex: 0 0 0;
    }
  }
}

.text>a {
  color: white;
  cursor: pointer;
  transition: color .2s;
}

.text>a:hover {
  color: lightgrey;
}

.slackContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.leftTextContainer {
  gap: 8px;
  // flex-direction: column;
}

.contentBox {
  overflow-y: auto;
  flex: 0 0 40%;
}

.contentBox>hr {
  margin: 20px 0;
}

.container {
  min-width: 200px;
  flex-basis: 0px;
}

.wrapper {
  color: white;
  flex: 1 1;
  border-radius: 20px;
  padding: 0px;
}

.wrapper.contentBox {
  padding: 25px 20px 20px 20px;
  height: 70vh;

  background-color: $secColor;
}

.wrapper.tipsContainer {
  overflow-y: auto;
  // padding: 0 8px;
}

.wrapper.bigContainer {
  min-width: 500px;
  background-color: $mainColor;
  padding: 0;
}

.bigContainer {
  height: 70vh;
}

.wrapperTitleBig {
  color: white;
  flex-grow: 0;
  border-radius: 20px;
  padding: 20px;
  font-size: 1.2rem;
  background: rgb(50, 30, 121);
  background: linear-gradient(180deg, rgba(50, 30, 121, 1) 0%, rgba(50, 30, 121, 1) 25%, rgba(30, 18, 71, 1) 100%);
}

.titleBig {
  text-align: center;
  font-family: 'Oregano';
}

.title {
  margin: 0px;
  font-size: 1.5rem;
  font-family: 'Oregano';
}

.titleWrapper {
  margin: 0px 2px;
  padding: 20px 0px;
  text-align: center;
  margin-bottom: 8px;
  border-radius: 20px;
  background-color: $secColor;
}

.titleSmallDot {
  text-transform: uppercase;
  display: list-item;
  margin: 20px 0px 10px 20px;
  font-size: 0.8rem;
}

.titleSmall {
  text-transform: uppercase;
  margin: 20px 0px 20px 0px;
  font-size: 1.5rem;
  font-family: 'Oregano';
}

.titleIngvar {
  text-align: center;
  font-size: 1.2rem;
  padding: 0px 0px;
  font-family: 'Oregano';
}

.ingvar {
  position: absolute;
  font-size: .9rem;
  font-style: italic;
  right: 0px;
  top: -25px;
  font-family: 'Oregano';
}

.textList {
  margin: 0px 0px 0px 20px;
  font-size: 0.8rem;
}

.text {
  font-size: 0.8rem;
  margin: 0px;
  line-height: 1.4;
}

.generalLink {
  color: white;
}

.listContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: stretch;
  gap: 8px;
  overflow-x: hidden;
  overflow-y: hidden;
}