@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

body {
  @media only screen and (max-width: 950px) {
    .wrapper {
      width: calc(100vw - 10px);
      margin-bottom: 0px;
    }

    .completeChallengeWrapper {
      padding: 40px 40px 40px 40px;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  color: white;
  text-align: center;
  border-radius: 20px;
  background-color: $secColor;
}

.title {
  font-family: 'Oregano';
  font-size: 2rem;
  margin: 10px 20px 0px 20px;
}

.text {
  font-style: italic;
  font-size: 1rem;
  margin: 10px 40px;
  font-family: 'Oregano';
}

.answerGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  padding: 20px 0px;
  gap: 10px;
  margin: 0px 10px;
}

.answerGrid>button {
  text-align: center;
  border-radius: 10px;
  font-size: 1rem;
  padding: 10px 10px;
  background-color: $blueSecColor;
  cursor: pointer;
  color: white;
  border: 0px;
  outline: 0px;
}

.backgroundOverLay {
  z-index: 100;
  display: flex;
  top: 0px;
  left: 0px;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.completeChallengeWrapper {
  background-color: $secColor;
  color: white;
  padding: 25px 100px 25px 100px;
  border-radius: 40px;
  position: relative;
}

.completeTitle {
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  font-family: 'Oregano';
}

.completeChallengeName {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 10px;
  font-family: 'Oregano';
}

.completeButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.startQuestionButton {
  text-align: center;
  border-radius: 10px;
  padding: 10px 0px;
  background-color: $blueSecColor;
  cursor: pointer;
  border: none;
  color: white;
  margin: 20px 20px;
  font-family: 'Oregano';
  font-size: 1.2rem;
}

.rightAnswer {
  font-family: 'Oregano';
  font-size: 1.2rem;
  text-align: center;
  border-radius: 10px;
  padding: 10px 0px;
  width: 100px;
  margin: 20px auto;
  background-color: $blueSecColor;
}

.wrongAnswer {
  font-family: 'Oregano';
  font-size: 1.2rem;
  text-align: center;
  border-radius: 10px;
  padding: 10px 0px;
  width: 100px;
  margin: 20px auto;
  background-color: red;
}

.readMore {
  text-align: center;
  border-radius: 10px;
  padding: 5px 0px;
  margin: 15px 40px 0px 40px;
  background: rgb(50, 30, 121);
  background: linear-gradient(180deg, rgba(50, 30, 121, 1) 0%, rgba(50, 30, 121, 1) 25%, rgba(30, 18, 71, 1) 100%);
  cursor: pointer;
  text-decoration: none;
  color: white;
  transition: 0.2s;
}

.readMore:hover {
  transform: scale(1.05);
}

.readMore>a {
  font-family: 'Oregano';
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
}

.readMore:hover {
  color: $blueSecColor;
  cursor: pointer;
}

.arrow {
  margin: auto 0px;
  font-size: 2rem;
}

.message {
  margin-bottom: 20px;
  display: flex;
  font-size: 1.2rem;
  justify-content: center;
  font-family: 'Oregano';
}

.closeButton {
  border-radius: 10px;
  width: 50px;
  padding: 5px 5px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  color: rgb(196, 45, 45);
  font-size: 2rem;
  font-family: 'Oregano';
  position: absolute;
  right: 10px;
  top: 10px;
}

.loading {
  height: 12px;
  animation: animateLoad 3s linear infinite;
}

@keyframes animateLoad {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}