@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

body {
  @media only screen and (max-width: 950px) {
    .wrapper {
      width: calc(100vw - 10px);
      margin-bottom: 0px;
    }

    .title {
      font-size: 2rem;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  position: relative;
  border-radius: 20px;
  background-color: $secColor;
  color: white;
  padding: 10px 0px 10px 0px;
}

.linkWrapper {
  display: flex;
  align-items: center;
  color: white;
  border: 0px;
  text-decoration: none;
  margin: 0 20px;
  cursor: pointer;
}

.widePageLinkWrapper {
  width: fit-content;
  white-space: nowrap;
  margin: 0 auto;
  padding: 5px 20px;
}


.textWrapper {
  font-family: 'Oregano';
  text-align: right;
  margin: 0 0 0 auto;
}

.channel {
  font-size: 1.1rem;
  font-family: 'Oregano';
  text-align: right;
  margin: 0 0 0 auto;
  background: transparent;
  color: white;
  border: none;
  outline: none;
  padding: 0px;
  text-decoration: none;
}

.title {
  font-size: 1.2rem;
}

.icon {
  min-width: 60px;
  max-width: 80px;
  width: 40%;
  margin: 0 auto 0 0px;
}