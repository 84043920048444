@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.disable_select {
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}

.ownItems {
    background-color: white;
    color: $mainColor;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    margin-bottom: 5px;
    position: relative;
    font-size: 12px;
    width: 100%;
    border: none;
}

.itemOverlay {
    display: none;
}

.ownItems:hover>.itemOverlay {
    position: absolute;
    background-color: rgba(0, 0, 0, .6);
    color: white;
    width: 100%;
    top: 0;
    left: 0;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}