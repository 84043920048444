@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.wrapper {
  border-radius: 20px;
  background-color: $secColor;
  height: 150px;
}

.header {
  text-align: center;
  color: white;
  font-family: 'Noto IKEA';
  font-size: 1.2rem;
  margin: 0px;
  padding: 10px;
  font-size: 2rem;
  font-family: 'Oregano';
}