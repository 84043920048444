.wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  height: 210px;
  border-radius: 20px;
  display: flex;
  background: url("../../../public/Background/grass.svg");
}

.flexWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
}

.userWrapper {
  display: grid;
  min-width: 200px;
  height: 100%;
  flex-wrap: wrap;
}

.userWrapper:first-child {
  margin-left: 10px;
}

.userWrapper>div {
  grid-area: 1/1/1/1;
}

.avatarWrapper,
.treeWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tree {
  position: absolute;
  bottom: -20px;
  width: 100%;
  max-width: 250px;
  z-index: 1;
}

.avatarWrapper>img {
  position: absolute;
  bottom: 0px;
  left: -20px;
  width: 50%;
  z-index: 2;
  max-width: 120px;
}

.loadingText::after {
  animation: loadingDots 2s infinite;
  content: '';
}

.grass {
  position: absolute;
  bottom: 0px;
  height: 217px;
  overflow-y: hidden;
  width: 100%;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  overflow-y: hidden;
  background-image: url("../../../public/Background/grass.svg");
}

@keyframes loadingDots {
  0% {
    content: '.';
  }

  33% {
    content: '..';
  }

  66% {
    content: '...';
  }

}