@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.disable_select {
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}

.itemOverlay {
    display: none;
}

.searchItem {
    border: none;
    border-bottom: 1px solid white;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 5px 5px;
    transition: backgroundColour .2s linear, color .2s linear;
    position: relative;
    width: 100%;
    margin: 0px;
    background: none;
    color: white;
}

.searchItem:last-child {
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.searchItem:hover {
    background-color: white;
    color: $mainColor;
    cursor: pointer;
}

.searchItem:hover>.itemOverlay {
    position: absolute;
    background-color: rgba(0, 0, 0, .6);
    color: white;
    width: 100%;
    top: 0;
    left: 0;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}