@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";


body {
  @media only screen and (max-width: 950px) {

    .fullWrapper {
      width: calc(100vw - 10px);
    }

    .responsiveCSS {
      flex-direction: column;
    }

    .wrapperTitleBig,
    .wrapper {
      width: calc(100vw - 20px);
    }
  }
}

.timeLineProjectsWrapper {
  height: 100%;
}

.wrapperTitleBig {
  font-family: 'Oregano';
  text-align: center;
  color: white;
  flex-grow: 0;
  border-radius: 20px;
  padding: 20px;
  font-size: 1.2rem;
  background: rgb(50, 30, 121);
  background: linear-gradient(180deg, rgba(50, 30, 121, 1) 0%, rgba(50, 30, 121, 1) 25%, rgba(30, 18, 71, 1) 100%);
}

.wrapper {
  display: inline-block;
  color: white;
  background-color: $secColor;
  flex-grow: 1;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.titleSmall {
  margin: 20px 0px 10px 0px;
  font-size: 1.5rem;
  font-family: 'Oregano';
}

.responsiveCSS {
  flex-grow: 1;
}

.disable_select {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

.saveSelectionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.updateProjectsBtn {
  border: 1px solid white;
  background-color: $secColor;
  color: white;
  border-radius: 5px;
  padding: 5px;
  width: 150px;
  flex: 0 0 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  transition: backgroundColor .2s, color .2s;
}

.updateProjectsBtn:hover {
  cursor: pointer;
  background-color: white;
  color: $secColor;
}

.timeLineProjects {
  height: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .8);
  z-index: 7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noOverlay {
  display: none;
}

.overlayModal {
  height: 20%;
  width: 80%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  padding: 15px;
  text-align: center;
}

a.sustLink {
  color: white;
  font-size: .8em;
  cursor: pointer;
}
