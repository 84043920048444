.world {
  animation: spin 100s linear infinite;
  transform-box: fill-box;
}

.windTurbine {
  animation: spin 10s linear infinite;
  transform-box: fill-box;
}

.waterWheel {
  animation: spin 10s linear infinite;
  width: 25%;
  z-index: -1;
}

.spinOne {
  animation: spin 12s linear reverse infinite;
  transform-box: fill-box;
}

.spinTwo {
  animation: spin 15s linear infinite;
  transform-box: fill-box;
}

.spinThree {
  animation: spin 20s linear reverse infinite;
  transform-box: fill-box;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}