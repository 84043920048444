@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.wrapper {
  border-radius: 20px;
  background-color: $secColor;
  height: 430px;
  width: 100%;
}

.wrapper>h1 {
  margin: 0px;
  color: white;
  font-size: 2rem;
  text-align: center;
  padding-top: 20px;
  font-family: 'Oregano';
}

.wrapper>div {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 20px;
  height: 300px;
  border-radius: 20px;
  background-color: white;
}

.wrapper>div>img {
  width: 100%;
}