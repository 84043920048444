@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.projectsContainer {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
}

.titleH5 {
    font-size: 1rem;
    font-family: 'Oregano';
    margin: 10px 0px;
}

.dropDownWrapper,
.dropDownWrapper * {
    box-sizing: border-box;
}

// contains the .searchInput & own list
.dropDownWrapper {
    display: flex;
}

// contains both the input and the search results
.searchInput {
    position: relative;
    display: flex;
    flex-grow: 1;
}

.searchInput>input {
    flex-grow: 1;
    border-radius: 5px;
    padding: 5px 3px;
    height: 30px;
}

.dropDownWrapper>button {
    border-radius: 5px;
    margin-left: 5px;
}

.searchResultsContainer {
    position: absolute;
    background-color: $mainColor;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    top: 30px; // needs to be the same as search-input height
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 5px;
    border: 1px solid white;
    z-index: 5;
}

.disable_select {
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}

.resultsShow {
    display: block;
}

.resultsNoShow {
    display: none;
}

.itemNoShow {
    display: none;
    background-color: white;
    color: $mainColor;
}

.ownList {
    // border: 1px solid white;
    flex: 0 0 200px;
    border-radius: 5px;
    overflow-y: auto;
}