@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";


body {
  @media only screen and (max-width: 950px) {
    .wrapper {
      margin: 5px;
      width: calc(100vw - 10px);
    }
  }
}

.wrapper {
  margin-right: 20px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.title {
  font-family: 'Oregano';
  text-align: center;
  color: white;
  flex-grow: 1;
  border-radius: 20px 20px 0px 0px;
  padding: 20px;
  font-size: 1.2rem;
  background: rgb(50, 30, 121);
  background: linear-gradient(180deg, rgba(50, 30, 121, 1) 0%, rgba(50, 30, 121, 1) 25%, rgba(30, 18, 71, 1) 100%);
}

.title>h2 {
  font-size: 2rem;
  margin-bottom: 0px;
}

.title>.info {
  margin-top: 0px;
}

.wrapperRealease>div {
  background-color: $secColor;
  color: white;
  padding: 30px;
  border-radius: 20px;
  margin: 10px 0px;
}