@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.updateAvatarContainer {
  height: 100%;
  position: relative;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  width: 100%;
  position: relative;
}

.btnContainer {
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnContainer>button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Oregano";
  font-size: 1.5rem;
  background-color: #0284FF;
  border-radius: 20px;
  border: none;
  color: white;
  height: 60%;
  width: 200px;
  cursor: pointer;
  transition: backgroundColor 1s;
}

.wrapper>img {
  max-width: 100%;
  width: 100%;
  max-height: 350px;
  height: 100%;
}

.loadingSave {
  height: 20px;
  width: 20px;
  animation: animateLoad 3s linear infinite;
}

.loading {
  background-color: lightslategrey !important;
}

.success {
  background-color: #2cb956 !important;
}

.saveErr {
  background-color: #b93f50 !important;
}

.disabledBtn {
  background-color: lightgray !important;
}

.showPinecones {
  position:absolute;
  top: 5px;
  right: 5px;
  background-color: $secColor;
  font-family: "Oregano";
  min-width: 70px;
  padding: 10px 15px;
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
}

.pineconeIcon {
  margin-right: 10px;
}

@keyframes animateLoad {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}